// todo: switch globally to vanilla JS
const cookiesToggler = () => {
    const cookiesBar = document.getElementById('cookiesBar'),
        cookiesBarBtn = document.getElementById('cookiesBarBtn');

    cookiesBarBtn.addEventListener('click', function(e) {
        e.preventDefault();
        cookiesBar.classList.toggle('cookies--approve');
    });
};

const bodyHiddenByPopUp = () => {
    $('#homePopUp').click(function(){
        $.scrollify.disable();
    });
    $('#homePopUpCloser').click(function(){
        $.scrollify.enable();
        setCookie('popUpCookies', 1, 7);
    });
};

const toggleContactBox = () => {
  const btn = $('#contactBoxSwitch'),
  col = $('#contactBoxAddress');

  $(btn).change(function(){
      col.toggleClass('first');
      col.toggleClass('last');
  });
};


$( document ).ready(function() {
    //cookiesToggler();
    bodyHiddenByPopUp();
    toggleContactBox();
});
